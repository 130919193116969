<template>
    <div>
        <div v-if="!addOrEditReady">
            <LoadingSpinner class="col-12" />
        </div>

        <div v-if="addOrEditReady">
            <form >
                <div class="row">
                    <div class="col-12" v-if="form_message !== ''">
                        <ErrorAlert :messageI18n="form_message" />
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <div  class="row d-flex justify-content-between mx-2">
                                <label for="horses" class="col-form-label">{{ $t("horse.horse") }} *</label>
                                <button  class="btn btn-primary btn-md mb-2 mt-n2" @click.prevent="addSire = true">{{$t("global.ajout_cheval")}}<font-awesome-icon :icon="['fas', 'plus']" class="ml-2"/></button>
                            </div>

                            <e-select
                                v-model="form.seasonmarestallion_horse"
                                id="horses"
                                track-by="name"
                                label="name"
                                :placeholder="labelTitle"
                                :deselectLabel="deselectLabel"
                                :selectedLabel="selectedLabel"
                                :selectLabel="enter_to_select"
                                :options.sync="horses"
                                :searchable="true"
                                :allow-empty="false"
                                :loading="isLoading"
                                :show-labels="false"
                                :multiple="false"
                                :closeOnSelect="true"
                                @search-change="onSearch"
                                :class="{ 'is-invalid': errors && errors.indexOf('Horses') > -1 }"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                            </e-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <div class="vue-custom-control-inline">
                                <b-form-checkbox id="seasonmarestallion_default" v-model="form.seasonmarestallion_default" unchecked-value="false" ><label for="seasonmarestallion_default">{{ $t("gynecologie.form.defaut") }}</label></b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <HorseAjout v-if="addSire" from="gyneco" :horse_id.sync="horse_id_added" :horseSexeMandatory="horseSexeMandatory" :horseToAddName.sync="horseToAddName" />
        </div>
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    import HorseMixin from "@/mixins/Horse.js"
    import GynecologieMixin from "@/mixins/Gynecologie.js"

    export default {
        name: "SeasonMareStallionAjout",
        props: ['seasonMare', 'addOrEditReady', 'seasonmarestallion_id', 'seasonMareStallion'],
        mixins: [Navigation, Tools, HorseMixin, GynecologieMixin],
        data () {
            return {
                labelTitle: this.getTrad("gynecologie.form.search_horse"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                selectedLabel: this.getTrad("global.selected_label"),
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                horses: [],
                horse_id_added: null,
                isLoading: false,
                horseSexeMandatory: ['M','H'],
                form_message: "",
                form: {
                    seasonmarestallion_horse: {},
                    seasonmarestallion_default: true
                },
                default_form: {
                    seasonmarestallion_horse: {},
                    seasonmarestallion_default: true
                },
                errors: [],
                loading: false,
                addSire: false,
                horseReload: false,
                horseToAddName: "",
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loading = true
                this.form_message = ""
                this.$emit('update:addOrEditReady', false)

                this.form = this.deppCloneObj(this.default_form)

				let seasonMareStallion = undefined
				// Si le parent a donné la liste des seasonMareStallions
                if(this.seasonMareStallion) {
					seasonMareStallion = this.seasonMareStallion.find(seasonMareStallion => seasonMareStallion.seasonmarestallion_id == this.seasonmarestallion_id)
				}
				// Sinon si on a un ID en cas d'édition on va le chercher en BDD
				else if (this.seasonmarestallion_id) {
					seasonMareStallion = await this.loadSeasonMareStallion(this.seasonmarestallion_id)
					seasonMareStallion.horse_id = seasonMareStallion.horse.horse_id
					seasonMareStallion.horse_nom = seasonMareStallion.horse.horse_nom
				}

                if(this.horses.length == 0 || this.horseReload) {
					this.horses = []
                    const horses = await this.getHorsesStallion(false)
                    if(horses.length > 0) {

						let blacklist = []
						if(this.seasonMareStallion) {
							this.seasonMareStallion.forEach(sms => {
								// Ne pas ajouter à la blacklist si c'est celui qu'on édite
								if(sms.seasonmarestallion_id != this.seasonmarestallion_id && (!seasonMareStallion || sms.horse_id != seasonMareStallion.horse_id)) {
									blacklist.push(sms.horse_id)
								}
							})
						}

						horses.forEach((horse) => {
							if(!blacklist.includes(horse.horse_id)) {
								this.horses.push({name: horse.horse_nom, id: horse.horse_id })
							}
                        })
                    }
				}

                if(seasonMareStallion) {
                    if(this.horses.length > 0) {
                        let horse = this.horses.find(horse => horse.id == seasonMareStallion.horse_id)
                        this.form.seasonmarestallion_horse = {name: horse.name, id: horse.id }
                    }

                    this.form.seasonmarestallion_default = (seasonMareStallion.seasonmarestallion_default ? true : false)
                }
                this.$emit('update:addOrEditReady', true)
                this.loading = false
            },
            checkForm: async function () {
                this.form_message = ""
                this.errors = []

                if(Object.keys(this.form.seasonmarestallion_horse).length == 0) {
                    this.errors.push("Horses")
                }

                if(this.form.seasonmarestallion_default === true) {
                    this.form.seasonmarestallion_default = true
                } else {
                    this.form.seasonmarestallion_default = false
                }

                if(this.errors.length > 0) {
                    return false
                } else {
                    let result = null
                    if(this.form.seasonmarestallion_default) {
                        await this.editAllSeasonMareStallionDefault(
                            this.seasonMare.seasonmare_id,
                            {seasonmarestallion_default: false}
                        )
                    }

                    if(this.seasonmarestallion_id) {
                        result = await this.editSeasonMareStallion(
                            this.seasonmarestallion_id,
                            {
                                "seasonmarestallion_horse": this.form.seasonmarestallion_horse.id,
                                "seasonmarestallion_default": this.form.seasonmarestallion_default
                            }
                        );
                    } else {
                        result = await this.addSeasonMareStallion(
                            this.seasonMare.seasonmare_id,
                            this.form.seasonmarestallion_horse.id,
                            null,
                            this.form.seasonmarestallion_default
                        );
                    }

                    if(result) {
                        return true
                    } else {
                        this.form_message = "error.LEP"
                        return false
                    }
                }
            },
            onSearch(val){
                if(val != ""){
                    this.horseToAddName = val
                }
            },
            async add_horse_to_stallion(){
                await this.updateHorse(this.horse_id_added, {horse_stallion: 1})
                this.horseReload  = true
                await this.init_component()
                this.form.seasonmarestallion_horse = this.horses.find(horse => horse.id == this.horse_id_added)
                this.addSire = false
                this.horseReload = false
            }
        },
        watch: {
            horse_id_added() {
                this.add_horse_to_stallion()
            },
        },
        components: {
            HorseAjout: () => import('@/views/HorseAjout'),
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
        }
    }
</script>
